import React from "react";
import { Layout } from "../components/Layout";
import { HeaderBuffer } from "../components/Layout/Header";

const NewslettersPage = () => {
  return (
    <Layout>
      <main className="min-h-[60vh]">
        <div className="bg-cyan-950">
          <HeaderBuffer />
        </div>
        <div className="w-full px-8 py-16 md:px-16 md:py-16 bg-cyan-900">
          <h1 className="text-white text-2xl text-left mb-4">Newsletters</h1>
          <h2 className="text-white text-5xl font-serif text-left">
            Quarterly updates on our ministry
          </h2>
        </div>
        <div className="px-8 pt-8 md:px-16 md:pt-16 flex flex-col space-y-2">
          <h3 className="font-bold text-cyan-900 text-xl">Latest</h3>
          <a
            href="/newsletters/wellspring-winter-2024-newsletter.pdf"
            className="text-lg text-blue-500 hover:underline"
            download
          >
            Download the Winter 2024 Newsletter
          </a>
        </div>
        <div className="px-8 pb-8 md:px-16 md:pb-16 mt-8 flex flex-col space-y-2">
          <h3 className="font-bold text-cyan-900 text-xl">
            Past Newsletters
          </h3>
          <div className="text-gray-500 text-lg">Check back soon!</div>
        </div>
      </main>
    </Layout>
  );
};

export default NewslettersPage;
